import React from "react";
import { Box, Typography, Link } from "@mui/material";

const Footer: React.FC = () => {
  return (
    <Box sx={{ textAlign: "center", py: 3, backgroundColor: "#002855", color: "#fff" }}>
      <Typography variant="body2">© 2024 Pack & Store. All rights reserved.</Typography>
      <Typography variant="body2">
        <Link href="mailto:brien@kempcoatings.ca" color="inherit" underline="hover">
        brien@kempcoatings.ca
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;
