import React from "react";
import { Container, Typography, Box } from "@mui/material";

const About: React.FC = () => {
  return (
    <Container sx={{ py: 5 }}>
      <Typography variant="h4" color="primary" gutterBottom>
        About Us
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Pack & Store is your trusted partner for secure and reliable storage solutions. Our mission is to provide
        state-of-the-art storage facilities designed with your safety and convenience in mind.
      </Typography>
      <Box
        sx={{
          mt: 3,
          p: 2,
          backgroundColor: "#FFFAF0",
          borderRadius: 2,
        }}
      >
        <Typography variant="h5" color="secondary" gutterBottom>
          Why Choose Us?
        </Typography>
        <Typography variant="body1" color="textSecondary">
          - Fenced, lit, and fully secure facilities.<br />
          - Affordable and flexible storage plans.<br />
          - Dedicated customer support.<br />
          - Convenient location for easy access.
        </Typography>
      </Box>
    </Container>
  );
};

export default About;
