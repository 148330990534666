import React from "react";
import { Box, Typography, Button, Stack } from "@mui/material";

const HeroSection: React.FC = () => {
  return (
    <Box
      sx={{
        height: "70vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: "#FFFAF0",
        px: 3,
      }}
    >
      <Typography variant="h3" color="primary" gutterBottom>
        Welcome to Pack & Store
      </Typography>
      <Typography variant="h5" color="textSecondary" gutterBottom>
        Fenced, Lit & Fully Secure
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
        568 Beach Rd <br />
        Hamilton ON L8H 3K9 <br />
        Canada
      </Typography>
      {/* Button Group */}
      <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
        <Button
          href="https://maps.apple.com/?address=568%20Beach%20Rd,%20Hamilton%20ON%20L8H%203K9,%20Canada&ll=43.256109,-79.800450&q=568%20Beach%20Rd"
          target="_blank"
          variant="contained"
          color="primary"
          size="large"
        >
          Get Directions
        </Button>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          href="tel:9059712011"
        >
          Contact Us
        </Button>
      </Stack>
    </Box>
  );
};

export default HeroSection;
