import React from "react";
import { Box, Typography } from "@mui/material";

const GoogleMap: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#FFFAF0", // Match the hero section background
        pt: 3,
        pb: 3,
        width: "100%",
      }}
    >
      <Typography variant="h5" sx={{ textAlign: "center", mb: 2, color: "#002855" }}>
        Find Us Here
      </Typography>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2887.4934713760143!2d-79.80263812419697!3d43.25559577913624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c9a19c46241d1%3A0x72653e20496d6365!2s568%20Beach%20Rd%2C%20Hamilton%2C%20ON%20L8H%203K9%2C%20Canada!5e0!3m2!1sen!2sus!4v1691602046100!5m2!1sen!2sus"
        width="100%"
        height="400px"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </Box>
  );
};

export default GoogleMap;
