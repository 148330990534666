import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import logo from "../assets/ps-logo.png"; // Import your logo

const Navbar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: "Home", path: "/" },
    { text: "About", path: "/about" },
    { text: "Services", path: "/services" },
  ];

  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: "#F5F5F5", color: "#002855" }}>
        <Toolbar>
          {/* Logo and title */}
          <Box
            component={Link}
            to="/"
            sx={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              color: "inherit",
              flexGrow: 1,
            }}
          >
            <img
              src={logo}
              alt="Pack & Store Logo"
              style={{ height: "50px", width: "50px", marginRight: "10px" }}
            />
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Pack & Store
            </Typography>
          </Box>

          {/* Hamburger menu for mobile */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ display: { md: "none" } }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>

          {/* Desktop navigation */}
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {menuItems.map((item) => (
              <Button
                key={item.text}
                color="inherit"
                component={Link}
                to={item.path}
                sx={{ ml: 2 }}
              >
                {item.text}
              </Button>
            ))}
            <Button
              color="inherit"
              href="tel:9059712011"
              sx={{ ml: 2 }}
            >
              Contact
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile navigation */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {menuItems.map((item) => (
              <ListItem key={item.text}>
                <ListItemText
                  primary={
                    <Button
                      component={Link}
                      to={item.path}
                      color="inherit"
                      sx={{
                        justifyContent: "left",
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      {item.text}
                    </Button>
                  }
                />
              </ListItem>
            ))}
            <ListItem>
              <ListItemText
                primary={
                  <Button
                    href="tel:9059712011"
                    color="inherit"
                    sx={{
                      justifyContent: "left",
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    Contact
                  </Button>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Navbar;
