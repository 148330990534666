import React from "react";
import { Box, TextField, Button, Typography, Container } from "@mui/material";

const Contact: React.FC = () => {
  return (
    <Container sx={{ py: 5 }}>
      <Typography variant="h4" color="primary" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Have questions or need more information? Fill out the form below, and we’ll get back to you as soon as possible.
      </Typography>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 3,
          maxWidth: 500,
        }}
      >
        <TextField
          label="Name"
          variant="outlined"
          required
          fullWidth
        />
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          required
          fullWidth
        />
        <TextField
          label="Message"
          variant="outlined"
          multiline
          rows={4}
          required
          fullWidth
        />
        <Button variant="contained" color="primary" size="large" type="submit">
          Submit
        </Button>
      </Box>
    </Container>
  );
};

export default Contact;
