import React from "react";
import { Container, Typography, Box, Grid, Paper } from "@mui/material";

const Services: React.FC = () => {
  const services = [
    { title: "Fenced Security", description: "Your storage units are protected by perimeter fencing for maximum security." },
    { title: "Well-Lit Areas", description: "Bright lighting ensures safety and visibility day and night." },
    { title: "Fully Secure Storage", description: "Advanced locks and surveillance to keep your items safe." },
  ];

  return (
    <Container sx={{ py: 5 }}>
      <Typography variant="h4" color="primary" gutterBottom>
        Our Services
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        At Pack & Store, we provide top-notch storage solutions tailored to your needs.
      </Typography>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                textAlign: "center",
                height: "100%",
              }}
            >
              <Typography variant="h6" color="secondary">
                {service.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {service.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Services;
