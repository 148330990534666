import React from "react";
import HeroSection from "../components/HeroSection";
import GoogleMap from "../components/GoogleMap";


const Home: React.FC = () => {
  return (
    <div>
      <HeroSection />
      <GoogleMap />

    </div>
  );
};

export default Home;
